* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

#root {
  height: 100vh;
}

body {
  background-color: white;
}

.card {
  width: 100%;
  height: fit-content;
}

.pagination {
  margin-bottom: 0;
}
